<template lang="pug">
    div
        v-dialog(persistent lazy max-width="1024px" v-model="note_pos")
            v-card(v-if="note_pos")
                v-card-title 
                    .btns.btns-right
                        v-btn(icon color="secondary" small @click.native="note_pos=false")
                            v-icon close
                    h3.full-width {{ $t('REFERIDOS DE') }} {{ current.names }}
                v-card-text.pt-2
                    v-text-field(v-model="filter.query" :placeholder="$t('Buscar por nombre, correo, teléfono...')")
                    v-data-table(
                        ref="table"
                        :headers='headers'
                        :items='network'
                        :pagination.sync='pagination'
                        :rows-per-page-items="$root.rowsPageItems"
                        :rows-per-page-text="$root.rowsPageText" 
                        item-key='names'
                        :total-items="total"
                        :loading="loading"
                    )   
                        template(slot="no-results")
                            | {{$t('No se han encontrado resultados')}}
                        template(slot="no-data")
                            | {{$t('No se han encontrado resultados')}}
                        template(slot='headers' slot-scope='props')
                            tr
                                th(v-for='header in props.headers' :key='header.text' :class="['column sortable', pagination.descending ? 'desc' : 'asc', header.value === pagination.sortBy ? 'active' : '']" @click='changeSort(header.value)' :align="header.align")
                                    span.subheading {{ $t(header.text) }}
                                    v-icon(small) arrow_upward
                        template(slot='items' slot-scope='props')
                            tr
                                td
                                    v-layout(align-center)
                                        v-flex.text-xs-center
                                            v-avatar.grey.lighten-4(size='27')
                                                img(v-if="photoUrl(props.item.photo).length" :src="photoUrl(props.item.photo)")
                                                v-gravatar(v-else :email="props.item.email")
                                        v-flex(xs12 d-flex align-center)
                                            | {{ props.item.names }}
                                td {{ props.item.total }}
                                td {{ props.item.totalshare }}
                                td
                                    | {{ $t('Fecha')}}: {{ props.item.datecreated | moment('YYYY-MM-DD') }} 
                                    br
                                    | {{$t('Hora')}}: {{ props.item.datecreated | moment('HH:mm') }}
                                td 
                                    v-icon.cur-p.primary--text(@click="$refs.notes.open(props.item.uuid, current, 'child')") comment
                        template(slot='pageText' slot-scope='props')
                            | Filas {{ props.pageStart }} - {{ props.pageStop }} de {{ props.itemsLength }}
        notes(v-if="note_pos" ref="notes")
</template>

<script>

import _ from 'lodash/debounce'
import config from 'src/config'

export default {
    data() {
        return {
            current: {},
            note_pos: false,
            total: 0,
            loading: false,
            preload: false,
            pagination: {
                page: 1,
                rowsPerPage: 100
            },
            headers: [
                {
                    text: 'Nombre',
                    align: 'left',
                    value: 'names'
                },
                {
                    text: 'Total de red',
                    align: 'left',
                    value: 'total'
                },
                {
                    text: 'Total de referidos',
                    align: 'left',
                    value: 'totalshare'
                },
                {
                    text: 'Fecha de registro',
                    align: 'left',
                    value: 'datecreated'
                },
                {
                    text: 'Opciones',
                    align: 'right',
                    value: 'options',
                    sortable: false
                }
            ],
            network: [],
            filter: {},
            filterSchema: {
                query: ''
            }
        }
    },
    watch: {
        filter: {
            handler(){

                this.pagination.page = 1
                this.preload = true
                this.total = 0
                this.network = []
                this.fetchNetwork()
            },
            deep: true
        },
        pagination: {
            handler () {
                this.fetchNetwork()
            },
            deep: true
        }
    },
    methods: {
        open(item) {

            this.filter = Object.assign({}, this.filterSchema)
            this.current = this._.cloneDeep(item)
            this.note_pos = true

            this.preload = true
            this.fetchNetwork()
        },
        fetchNetwork: _(function(){

            this.loading = true
            this.$api(this, (xhr) => {
                xhr.get('/report/network', {
                    params: {
                        filter: JSON.stringify({
                            ...this.filter, ...{
                            network: this.current.networkid,
                            referid: this.current.uuid
                        }}),
                        limit  : this.pagination.rowsPerPage,
                        offset : (this.pagination.page-1)*this.pagination.rowsPerPage,
                        sort   : this.pagination.sortBy,
                        order  : (this.pagination.descending ? 'DESC' : 'ASC'),
                    }
                }).then((r) => {

                    let data = r.data
                    this.preload = false
                    this.loading = false

                    if(data.response){
                        let network  = data.data.result
                        this.total = data.data.total
                        this.network = network
                    }
                }).catch(() => {})
            })
        }, 500),

        changeSort (column) {

            if(column == 'multiple'){
                return
            }
            
            if (this.pagination.sortBy === column) {
                this.pagination.descending = !this.pagination.descending
            } else {
                this.pagination.sortBy = column
                this.pagination.descending = false
            }
        },

        photoUrl(photo){

            if(photo != null && photo.length){
                return config.uploaduser + photo
            }
            return ''
        }
    }
}
</script>